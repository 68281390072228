/* Import Google Font */
body {
  font-family: 'Roboto', Arial, sans-serif; /* Use Google Font Roboto */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #000; /* Light background for contrast */
  color: #ffffff; /* Darker color for better readability */
}

/* Main App container */
.App {
  text-align: center;
  margin-top: 20px;
  padding: 20px; /* Add padding for mobile responsiveness */
}

/* Form container for upload */
.upload-container {
  padding: 25px;
  background-color: #1a1a20;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 2000px; /* Responsive max-width */
  margin: 30px auto; /* Center the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Form styling */
.form-group {
  margin-bottom: 15px;
  text-align: left; /* Align form labels to the left */
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group select,
.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

/* Button styling */
button {
  background-color: #31bdec; /* Green background */
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #113f60; /* Darker green on hover */
}

/* Success Message */
.message {
  margin-top: 20px;
  color: green;
  font-size: 16px;
  font-weight: bold;
}

/* Document List */
.document-list {
  margin-top: 40px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5;
}

/* Styling for the results table */
.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.results-table th,
.results-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
  line-height: 1.6; /* Improve readability */
}

.results-table th {
  background-color: #362f2f;
  font-weight: bold;
}

/* Paragraph and Pre styling for results content */
pre,
p {
  text-align: left;
  line-height: 1.8;
  font-size: 16px;
  white-space: pre-wrap; /* Ensures long text wraps properly */
  word-wrap: break-word;
  margin: 20px 0;
}

/* Add spacing between sections for better readability */
h1, h2, h3, h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #60d9f4; /* Use green accent color */
}

/* Style for progress bar container */
.progress-bar-container {
  margin-top: 20px;
  text-align: center;
}

.progress-bar {
  width: 100%;
  max-width: 500px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  height: 20px;
  border-radius: 5px;
  margin: 0 auto;
}

.progress {
  background-color: #4caf50;
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s;
}

.formatted-result h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  color: #333;
}

.formatted-result p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

.result-section {
  margin-top: 30px;
  background-color: #1a1a20;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* Container for proofreading results */
.proofreading-results {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9; /* Light background for better contrast */
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* Each proofreading section */
.proofreading-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #007bff; /* Blue border for distinction */
}

/* Header for each section */
.proofreading-section p strong {
  font-size: 1.2rem;
  color: #007bff; /* Same blue as the border */
  margin-bottom: 10px;
}

/* Style for different categories in proofreading results */
.grammatical-errors {
  color: #dc3545; /* Red for grammatical errors */
  font-weight: bold;
  margin-top: 10px;
}

.suggested-improvements {
  color: #28a745; /* Green for suggested improvements */
  font-weight: bold;
  margin-top: 10px;
}

.feedback {
  color: #ffc107; /* Yellow for feedback */
  font-weight: bold;
  margin-top: 10px;
}

.pre {
  white-space: pre-wrap; /* Preserve formatting in the results */
  font-family: 'Courier New', Courier, monospace;
  color: #ffffff;
}

/* Logo styling */
.logo {
  text-align: center;
  margin: 20px 0; /* Adds some space above and below the logo */
}

.logo img {
  width: 150px; /* Set the width of the logo */
  height: auto; /* Ensures the aspect ratio is maintained */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .upload-container {
    width: 90%;
    padding: 20px;
  }

  button {
    width: 100%;
  }

  .results-table th, .results-table td {
    padding: 8px 10px;
  }
}